/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

export const sendClickEvent = (email: string, label: string, location: string): void => {
  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
    ...abTestData,
    ...utmCookieData,
    email,
    label,
    location,
  });
};
