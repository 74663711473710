/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';

import Link from '@/components/shared/Link';

import { sendClickEvent } from '../posts/full/utils';

import { useAuth0 } from '@/context/auth';

import s from './index.module.css';
import { useRouter } from 'next/router';

export default function Insticator({ redirectURL, id, showCTA, adsShouldDisplay }) {
  const [displayLoginView, setDisplayLoginView] = React.useState(true);
  const { loading: userLoading, subscriber, loginWithRedirect, subscriptionLoading } = useAuth0();
  const showAds = adsShouldDisplay ? !subscriber || subscriber.effectivePlan === 'FREE' : false;
  const [reloadKey, setReloadKey] = React.useState(0);
  const router = useRouter();
  const adRefs = React.useRef([]);

  React.useEffect(() => {
    // @ts-ignore
    if (showAds && !userLoading && typeof window !== 'undefined' && window.tude) {
      (window as any).gtag('event', 'ad_requested', {
        ad_id: '2',
      });
      // @ts-ignore
      window.tude = window.tude || { cmd: [] };
      // @ts-ignore
      window.tude.cmd.push(function () {
        // @ts-ignore
        window.tude.refreshAdsViaDivMappings([
          {
            divId: 'pb-slot-commenting-1',
            baseDivId: 'pb-slot-commenting-1',
          },
          {
            divId: 'pb-slot-commenting-2',
            baseDivId: 'pb-slot-commenting-2',
          },
        ]);
      });
    }
  }, [showAds, userLoading, router.asPath]);

  React.useEffect(() => {
    async function sha512(str: string): Promise<string> {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const buffer = await crypto.subtle.digest('SHA-512', data);
      return Array.prototype.map
        .call(new Uint8Array(buffer), (x: number) => ('00' + x.toString(16)).slice(-2))
        .join('');
    }

    if (!subscriptionLoading && subscriber) {
      // @ts-ignore
      setReloadKey((prev) => prev + 1);
      const secretKey = process.env.NEXT_PUBLIC_INSTICATOR_SECRET_KEY;
      const commentingString = `${subscriber.email};${secretKey}`;
      // For Insticator username no special
      // characters and max length 16
      const username = subscriber?.userName?.replaceAll(/[^a-z0-9]/gi, '').substr(0, 16);

      sha512(commentingString).then((hash) => {
        const payload = {
          uuid: subscriber?.authZeroID,
          name: username,
          email: subscriber?.email?.toLowerCase(),
          signature: hash,
        };

        const payloadString = JSON.stringify(payload);

        // Encode payload to UTF-8 bytes
        const encoder = new TextEncoder();
        const utf8Bytes = encoder.encode(payloadString);

        // Convert UTF-8 bytes to Base64
        const encodedPayload = btoa(String.fromCharCode.apply(null, utf8Bytes));
        // @ts-ignore
        window.Insticator.load('customSSO', {
          SSOToken: encodedPayload,
          openAuth: function () {
            sendClickEvent('', 'Start Commenting', 'Start Commenting - Insticator');
            loginWithRedirect({
              initialScreen: 'login',
              pageIntroduction: 'subscribe',
              appState: {
                targetUrl: redirectURL,
              },
            });
          },
        });
        // @ts-ignore
        window.Insticator.load('load-page', { pageId: `${id}` });
        // @ts-ignore
        window.Insticator.load('insticator-product-group', { id: '4866a133-c7d9-44df-855a-13d6dfa58fc6' });
        setDisplayLoginView(false);
      });
    } else if (!subscriptionLoading && !subscriber) {
      setReloadKey((prev) => prev + 1);
      // @ts-ignore
      window.Insticator.load('customSSO', {
        SSOToken: null,
        openAuth: function () {
          sendClickEvent('', 'Start Commenting', 'Start Commenting - Insticator');
          loginWithRedirect({
            initialScreen: 'login',
            pageIntroduction: 'subscribe',
            appState: {
              targetUrl: redirectURL,
            },
          });
        },
      });
      // @ts-ignore
      window.Insticator.load('load-page', { pageId: `${id}` });
      // @ts-ignore
      window.Insticator.load('insticator-product-group', { id: '4866a133-c7d9-44df-855a-13d6dfa58fc6' });
    }
  }, [subscriber, subscriptionLoading, router.asPath, id, loginWithRedirect, redirectURL]);

  React.useEffect(() => {
    const currentRefs = adRefs.current;
    const adHasBeenViewed = {
      'pb-slot-commenting-1': false,
      'pb-slot-commenting-2': false,
    };
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !adHasBeenViewed[entry.target.id]) {
            adHasBeenViewed[entry.target.id] = true;
            (window as any).gtag('event', 'ad_loaded', {
              ad_id: entry.target.id,
            });
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    currentRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) {
          observer.observe(ref);
        }
      });
    };
  }, [adRefs]);

  return (
    <>
      {showCTA && displayLoginView ? (
        <>
          <div
            css={{
              width: '100%',
              maxWidth: '728px',
              boxSizing: 'border-box',
              margin: '0 auto',
              padding: '40px 20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '28px',
            }}
          >
            <h2 className={s.insticatorCreateAccountHeader}>Create a free account to join the conversation!</h2>
            <button
              // @ts-ignore
              css={{
                backgroundColor: '#b33828',
                color: 'white',
                padding: '12px 28px',
                borderRadius: '6px',
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: '600',
                maxWidth: '300px',
                border: 'none',
              }}
              onClick={() => {
                sendClickEvent('', 'Start Commenting', 'Start Commenting - DW');
                loginWithRedirect({
                  initialScreen: 'signUp',
                  pageIntroduction: 'subscribe',
                  appState: {
                    targetUrl: redirectURL,
                  },
                });
              }}
            >
              Start Commenting
            </button>
            <div
              className={s.insticatorLoginCTA}
              css={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>Already have an account?</p>
              <Link
                // @ts-ignore
                css={{
                  boxSizing: 'border-box',
                  margin: '0 auto',
                  textAlign: 'center',
                  fontWeight: '600',
                  display: 'block',
                  textDecoration: 'underline',
                }}
                href='/login'
                onClick={(event) => {
                  event.preventDefault();
                  sendClickEvent('', 'Log In', 'Start Commenting - DW');
                  loginWithRedirect({
                    appState: { targetUrl: window.location.pathname },
                  });
                }}
              >
                Log in
              </Link>
            </div>
          </div>
        </>
      ) : null}
      <>
        {adsShouldDisplay && showAds && (
          <div className={`cmt-ads ${s.adContainer}`}>
            <div id='pb-slot-commenting-1' ref={(el) => (adRefs.current[0] = el)} />
            <div id='pb-slot-commenting-2' ref={(el) => (adRefs.current[1] = el)} />
          </div>
        )}
        <div
          className='insticator-product-group'
          css={{
            width: '100%',
            boxSizing: 'border-box',
            margin: '0 auto',
            padding: '0 20px',
          }}
          data-insticator-group-id='4866a133-c7d9-44df-855a-13d6dfa58fc6'
          key={`insticator-unit-${reloadKey}`}
        />
      </>
    </>
  );
}
